// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > :nth-child(1) {
    margin-bottom: ${({ theme }) => rem(theme.spacing.xl)};
  }

  & > :nth-child(2) {
    margin-bottom: ${({ theme }) => rem(theme.spacing.lg)};
  }

  ${({ dimmed }) =>
    dimmed &&
    css`
      opacity: 0.3;
    `};
`;

export default Container;
