// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Bar = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: ${rem(12)};
  font-weight: 300;
  height: 100%;
  overflow: hidden;
  padding: 0 ${({ theme }) => rem(theme.spacing.md)};
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 888;
`;

export default Bar;
