// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div.attrs({ role: 'progressbar' })`
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  border-radius: ${rem(2)};
  display: flex;
  height: ${rem(32)};
  justify-content: flex-start;
  position: relative;
`;

export default Container;
