// @flow

import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Bar = styled.div`
  background-color: ${({ theme }) => theme.colors.polishedPine};
  border-radius: 3px;
  margin-right: ${({ theme }) => rem(theme.spacing.sm)};
  width: ${rem(3)};
`;

const Bottom = styled.h2`
  color: ${({ theme }) => theme.colors.jet};
  font-size: ${rem(14)};
  font-weight: 500;
  margin: ${({ theme }) => rem(theme.spacing.xs)} 0 0 0;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Top = styled.h1`
  color: ${({ theme }) => theme.colors.rhythm};
  font-size: ${rem(12)};
  font-weight: 500;
  letter-spacing: ${rem(0.43)};
  margin: 0;
  text-transform: uppercase;
`;

type Props = { bottom: string, top: string };
const Label = ({ bottom, top }: Props) => (
  <Container>
    <Bar />
    <Text>
      <Top>{top}</Top>
      <Bottom>{bottom}</Bottom>
    </Text>
  </Container>
);

export default Label;
