// @flow

import React from 'react';

import Bar from './Bar';
import Container from './Container';
import TextUnderBar from './TextUnderBar';

type Props = { children?: string, dataTestId?: string, valueMax: number, valueMin?: number, valueNow: number };

const Progress = ({ children, dataTestId = 'atom-progress', valueMax, valueMin = 0, valueNow }: Props) => {
  const percent = valueNow / valueMax;
  return (
    <Container aria-valuemax={valueMax} aria-valuemin={valueMin} aria-valuenow={valueNow} data-testid={dataTestId}>
      <Bar style={{ width: `${percent * 100}%` }}>{children}</Bar>
      <TextUnderBar>{children}</TextUnderBar>
    </Container>
  );
};

export default Progress;
