// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Bar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.polishedPine};
  border-radius: ${rem(2)};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: ${rem(12)};
  font-weight: 300;
  overflow: hidden;
  padding: 0 ${({ theme }) => rem(theme.spacing.md)};
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 999;
`;

export default Bar;
